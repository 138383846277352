import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA USER------
    getUser(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/user", { params: credentials })
    },
    getUserSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/user/single", { params: credentials } )
    },
    addUser(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/user", credentials)
    },
    updateUser(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/master/user`, credentials)
    },
    updateUserPass(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/master/user/pass`, credentials)
    },
    deleteUser(credentials) {
        ApiService.setHeader();
        return ApiService.delete(`v2/master/user`, { data: credentials })
    },

    // -----API DATA USER AKSES------
    getUserAkses(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/user/akses", { params: credentials })
    },
    updateUserAkses(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/master/user/akses`, credentials)
    },

}